/* Component Dependencies */
var hotelProductOfferingsTemplate = require('templates/hotelProductOfferings.hbs');
var AriesComponent = require('libs/aries-component');

AriesComponent.create({
  type: 'hotelProductOfferings',
  template: {
    'hotelProductOfferings': hotelProductOfferingsTemplate
  }
});
